<template>
  <el-container>
    <el-row>
      <el-col :xs="2" :sm="2" :md="5" :lg="5" class="burger left_column">
        <el-aside style="width: initial; height: 100%;">
          <el-image :src="require('@/assets/logo.svg')" class="logo hidden-sm-and-down"></el-image>
          <Burger/>
        </el-aside>
      </el-col>
      <el-col :xs="22" :sm="22" :md="19" :lg="19" class="right_column">
        <el-main>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-space>
                <h2>{{ classTitle }}</h2>
                <el-button type="text" @click="onLogout()">
                  <el-image :src="require('@/assets/exit.svg')" class="exit"></el-image>
                </el-button>
              </el-space>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :xs="24" :md="20">
              <h3 class="doc">Мастера</h3>
              <el-divider></el-divider>
                <div v-for="link in links" :key="link" :span="12" @click="getMastersLinks()" :xs="24" :md="12">
                  <span class="name">{{ link.full_name }}:  </span>
                  <el-link :href="link.social_network" target="_blank" class="float">
                    {{ link.social_network }}
                  </el-link>
                  <el-divider></el-divider>
                </div>
            </el-col>
          </el-row>
        </el-main>
        <el-footer>
          <span>Полититка конфедициальности</span><br>
          <span>let.tobe.happy@gmail.com</span>
        </el-footer>
      </el-col>
    </el-row>
  </el-container>
</template>

<script>

import Burger from '../components/Burger.vue';
import 'element-plus/lib/theme-chalk/display.css';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
  LOGOUT_ACTION,
  GET_USER_TOKEN_GETTER,
  LOADING_SPINNER_SHOW_MUTATION,
} from '../store/storeconstants';
import axiosInstance from "../services/AxiosTokenInstance";

export default {
  data() {
    return {
      links: [],
      doc_title: {
        books: '',
        presentation: '',
        bonus: '',
        gift: ''
      }
    };
  },
  computed: {
    ...mapGetters('auth', {
      token: GET_USER_TOKEN_GETTER,
    }),
  },
  components: {
    Burger,
  },
  mounted() {
    axiosInstance
        .get(`https://betterlife-school.ru/app/api/masters`)
        .then((response) => {
          this.getMastersLinks(response.data);
          this.showLoading(false);
        })
        .catch(() => {
          this.showLoading(false);
        });
  },
  methods: {
    ...mapActions('auth', {
      logout: LOGOUT_ACTION,
    }),
    ...mapMutations({
      showLoading: LOADING_SPINNER_SHOW_MUTATION,
    }),
    onLogout() {
      this.logout();
      this.$router.replace('/login');
    },
    getMastersLinks(links) {
      for (let link in links) {
        this.links.push({ ...links[link] });
      }
    }
  },
};
</script>

<style>
@media only screen and (min-width: 992px) {
  .bm-menu {
    min-width: 20%;
    margin-top: 13%;
  }

  .bm-burger-button {
    display: none;
  }

  .bm-cross-button {
    display: none;
  }
}

.courses_card .el-card {
  background-color: #F4F4F6;
  border-radius: 15px;
}
</style>

<style scoped>
.float {
  float: right;
}

.logo {
  position: fixed;
  padding: 10px;
  margin-bottom: 20px;
  width: 15%;
  left: 2%;
  top: 5%;
}

.el-main {
  height: 100%;
  padding-bottom: 100px;
}

.el-space {
  display: flex;
  justify-content: space-between;
}

.el-container {
  background: #ffffff;
}

.el-header, .el-footer {
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 60px;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.activation_block {
  padding-top: 30px;
  text-align: center;
  display: grid;
  line-height: 2;
}

.price {
  font-family: 'Montserrat', sans-serif !important;
  text-transform: uppercase;
  font-weight: 300 !important;
  font-style: normal !important;
  font-size: 16px !important;
}

.name {
  font-family: 'Montserrat', sans-serif !important;
  text-transform: uppercase;
  font-weight: 300 !important;
  font-style: normal !important;
  font-size: 11px !important;
}

.discount {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 16px !important;
  color: #5E597B;
  margin-top: 16px;
}

.doc_button {
  margin: 0 0 0 5px !important;
}

.doc_space {
  justify-content: start;
}
</style>
